<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">
                {{ $t('elearning_tim.honorarium') }} {{ $t('globalTrans.search') }}
              </h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.organization')"
                        label-for="organization"
                    >
                      <v-select name="org_id"
                        v-model="search.org_id"
                        label="text"
                        :reduce="item => item.value"
                        :options="orgList"
                        disabled
                        :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.fiscal_year')}}
                      </template>
                      <v-select name="fiscal_year_id"
                        v-model="search.fiscal_year_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= fiscalYearList
                        :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tim.honorarium') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <!-- <template v-slot:headerAction>
                      <router-link to="bill-payment-form" :class="'btn btn_add_new font-weight-bold'"><i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}</router-link>
                    </template> -->
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                          <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                    <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(type_name)="data">
                                      <span class="capitalize">{{ data.item.type_name }}</span>
                                    </template>
                                    <template v-slot:cell(personal_info_id)="data">
                                      {{ ($i18n.locale==='bn') ? data.item.personal.name_bn : data.item.personal.name }}
                                    </template>
                                    <template v-slot:cell(details)="data">
                                      <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item.circular_memo_no, data.item.batch_no, data.item.mobile, data.item.payment_date, data.item.name, data.item.name_bn, data.item.p_designation_en, data.item.p_designation_bn, data.item.p_office_name, data.item.p_office_name_bn)"><i class="fas fa-eye"></i></a>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                      <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-6 @click="honorariumDetails(data)"><i class="fas fa-eye"></i></a>
                                    </template>
                                </b-table>
                                <b-pagination
                                    align="center"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                    />
                            </b-col>
                          </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('elearning_tim.individual_honorarium_title')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            {{  $t('globalTrans.export_pdf') }}
          </b-button>
          <Details :item="item" :key="id" ref="details"></Details>
        </b-modal>
        <b-modal id="modal-6" size="lg" :title="$t('elearning_tim.speaker_honorarium')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <b-button variant="primary" @click="pdfExportHonaraium" class="ml-4 btn-success water-test-pdf-button">
            {{  $t('globalTrans.export_pdf') }}
          </b-button>
          <HonorariDetails :item="honorariumItem" :key="id" ref="details"></HonorariDetails>
        </b-modal>
        <!-- <b-modal id="modal-4" size="lg" :title="$t('elearning_venue.receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
              <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
                {{  $t('globalTrans.export_pdf') }}
              </b-button>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal> -->
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './IndividualDetails'
import HonorariDetails from './IndividualHonorariumDetails'
// import Attachment from './Attachment'
// import Payment from './Payment'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details,
      HonorariDetails
    //   Details
    },
    data () {
        return {
          search: {
            org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
            payment_type: 'Trainer',
            fiscal_year_id: 0
          },
          item: [],
          honorariumItem: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
      orgList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      fiscalYearList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_tim.bill_payment_entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
              { label: this.$t('elearning_config.fiscal_year'), class: 'text-center' },
              { label: this.$t('elearning_config.training_title'), class: 'text-center' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
              { label: this.$t('elearning_iabm.batch_no'), class: 'text-center' },
              { label: this.$t('elearning_tim.tax_certificate'), class: 'text-center' },
              { label: this.$t('elearning_tim.honorarium'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
            keys = [
              { key: 'index' },
              { key: 'fiscal_year_bn' },
              { key: 'training_title' },
              { key: 'circular_memo_no' },
              { key: 'batch_name_bn' },
              { key: 'details' },
              { key: 'action' }
            ]
          } else {
            keys = [
              { key: 'index' },
              { key: 'fiscal_year' },
              { key: 'training_title' },
              { key: 'circular_memo_no' },
              { key: 'batch_name' },
              { key: 'details' },
              { key: 'action' }
            ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            // org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
        // if (this.$store.state.Auth.activeRoleId !== 1) {
        //   this.search.org_id = this.$store.state.Auth.authUser.org_id
        //   if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
        //     this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
        //   }
        // }
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
    },
    methods: {
      async searchData () {
          this.loadData()
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, billPaymentList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const divisionList = this.$store.state.commonObj.divisionList
        const districtList = this.$store.state.commonObj.districtList
        const upazilaList = this.$store.state.commonObj.upazilaList
        const unionList = this.$store.state.commonObj.unionList
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const batchObj = this.allBatchList.find(batch => batch.value === parseInt(item.batch_no))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch_name = batchObj.text_en
            batchData.batch_name_bn = batchObj.text_bn
          } else {
            batchData.batch_name = ''
            batchData.batch_name_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
          if (typeof desigObj !== 'undefined') {
            desigData.designation_name = desigObj.text_en
            desigData.designation_name_bn = desigObj.text_bn
          } else {
            desigData.designation_name = ''
            desigData.designation_name_bn = ''
          }
          const divisionObject = divisionList.find(division => division.value === item.pre_division_id)
          const districtObject = districtList.find(district => district.value === item.pre_district_id)
          const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.pre_upazilla_id)
          const unionObject = unionList.find(union => union.value === item.pre_union_id)
          const divisionData = {
              division_name: typeof divisionObject !== 'undefined' ? divisionObject.text_en : '',
              division_name_bn: typeof divisionObject !== 'undefined' ? divisionObject.text_bn : ''
          }
          const districtData = {
            district_name: typeof districtObject !== 'undefined' ? districtObject.text_en : '',
            district_name_bn: typeof districtObject !== 'undefined' ? districtObject.text_bn : ''
          }
          const upazillaData = {
            upazilla_name: typeof upazilaObject !== 'undefined' ? upazilaObject.text_en : '',
            upazilla_name_bn: typeof upazilaObject !== 'undefined' ? upazilaObject.text_bn : ''
          }
          const unionData = {
            union_name: typeof unionObject !== 'undefined' ? unionObject.text_en : '',
            union_name_bn: typeof unionObject !== 'undefined' ? unionObject.text_bn : ''
          }
          const address = divisionData.division_name + ',' + districtData.district_name + ',' + upazillaData.upazilla_name + ',' + unionData.union_name
          const addressData = {
            address: address
          }
          return Object.assign({}, item, orgData, batchData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, desigData, addressData)
        })
        return listData
      },
      details (data, data1, data2, data3, data4, data5, data6, data7, data8, data9) {
        this.item.circular_memo_no = data
        this.item.batch_no = data1
        this.item.mobile = data2
        this.item.payment_date = data3
        this.item.name = data4
        this.item.name_bn = data5
        this.item.designation = data6
        this.item.designation_bn = data7
        this.item.office = data8
        this.item.office_bn = data9
      },
      honorariumDetails (data) {
        this.honorariumItem = data.item
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      },
      pdfExportHonaraium () {
        this.$refs.details.pdfExportHonaraium()
      }
    }
}
</script>
