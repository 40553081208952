<template>
    <b-container fluid>
        <b-row>
          <b-col lg="12" sm="12" id="print">
            <iq-card>
                <!-- <template v-slot:headerAction>
                    <b-button variant="primary" @click="pdfExport" class="">
                        <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
                    </b-button>
                    <b-button type="button" variant="primary" class="mr-2" @click="wordDownloadExport">{{ $t('elearning_tim.download_word') }}</b-button>
                </template> -->
                <template>
                    <b-overlay :show="load">
                        <b-row>
                            <b-col>
                                <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="org_id">
                                    {{ '' }}
                                </list-report-head>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-center mb-2">
                                <b>
                                    {{ 'Training Course' }}<br />
                                    {{ 'on' }}<br />
                                    {{ (($i18n.locale=='en') ? trainees.title_name_en : trainees.title_name_bn) }}
                                </b>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-center">
                               <u> {{ $t('elearning_tim.speaker_honorarium') }}</u>
                            </b-col>
                        </b-row>
                        <b-row v-if="trainees.length !== 0">
                            <b-col lg="12" sm="12" class="pl-5 pr-5">
                                <b-row>
                                    <b-col lg="12" sm="12" class="text-left mt-1 mb-3">
                                        <p>{{ $t('elearning_tim.individual_honorarium_P_1') + ' ‘‘' + (($i18n.locale=='en') ? trainees.title_name_en : trainees.title_name_bn) + '’’ ' + $t('elearning_tim.individual_honorarium_P_2') + ' ' + ($i18n.locale=='en' ? formattedDate2 : formattedDate1) + ' ' + $t('elearning_tim.individual_honorarium_P_3') + ' ' + (($i18n.locale=='en') ? '' : ($n(trainees.total_days) + ' (' + ($i18n.locale=='en' ? convertToEnglish(trainees.total_days) : convertToBengali(trainees.total_days)) + ') ')) + $t('elearning_tim.individual_honorarium_P_4') + ' ' + ' ' +  $n(trainees.honour_amount * trainees.total_days)}} ({{ parseFloat(trainees.honour_amount * trainees.total_days) | convertNumberWords }}) {{ $t('elearning_tim.individual_honorarium_P_5') + ($i18n.locale=='en' ? ' ' + $t('elearning_tim.taka') + '.' : '')  }}</p>
                                    </b-col>
                                </b-row>
                                <b-table-simple border="1">
                                    <b-tr>
                                        <!-- <b-th style="width:15%">{{ $t('globalTrans.date') }}</b-th> -->
                                        <b-th class="text-center" style="width:10%">{{ $t('elearning_tim.individual_honorarium_P4') }}</b-th>
                                        <b-th class="text-center" style="width:15%">{{ $t('elearning_tim.individual_honorarium_P') }}</b-th>
                                        <b-th class="text-center" style="width:15%">{{ $t('elearning_tim.individual_honorarium_P1') }}</b-th>
                                        <b-th class="text-center" style="width:15%">{{ $t('elearning_tim.individual_honorarium_P2') + ' (' + $n(trainees.vat_tax, { minimumFractionDigits: 2 }) + '% )' + $t('globalTrans.taka') }}</b-th>
                                        <b-th class="text-center" style="width:15%">{{ $t('elearning_tim.individual_honorarium_P3') }}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <!-- <b-td>{{ ($i18n.locale=='en') ? item.name_en : item.name_bn }}</b-td> -->
                                        <!-- <b-td>{{ trainees.payment_date|dateFormat }}</b-td> -->
                                        <b-td class="text-center">{{ $n(trainees.total_days) }}</b-td>
                                        <b-td class="text-center">{{ $n(trainees.honour_amount) }}</b-td>
                                        <b-td class="text-center">{{ $n(trainees.honour_amount * trainees.total_days) }}</b-td>
                                        <b-td class="text-center">{{ $n(trainees.vat_tax_amount, { minimumFractionDigits: 2 }) }}</b-td>
                                        <b-td class="text-center">{{ $n(trainees.payment_amount, { minimumFractionDigits: 2 }) }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="text-center" colspan="5">{{ $t('elearning_tim.in_words') + ' : ' }} {{ parseFloat(trainees.honour_amount * trainees.total_days) | convertNumberWords }} {{ '(' + $t('elearning_tim.taka') + ')' }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                        <p></p>
                        <table style="margin-top:20px !important;padding-top:20px !important;width: 100%;">
                            <tr>
                                <td style="width: 60%;"></td>
                                <td style="text-align: center;">
                                    <p></p>
                                    <p></p>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 60%;"></td>
                                <td style="text-align: center;">
                                    <p></p>
                                    <p></p>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 60%;"></td>
                                <td style="text-align: center;">
                                    <p></p>
                                    <p></p>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 60%;"></td>
                                <td style="text-align: center;">
                                    {{ ($i18n.locale=='en' ? venueMnagement.name : venueMnagement.name_bn) }}<br />
                                    {{ getDesignationData(venueMnagement, 'panel') }}<br />
                                    {{ getWorkPlace(venueMnagement, 'panel') }}
                                </td>
                            </tr>
                        </table>
                        <!-- <b-row class="mt-5">
                            <b-col sm="7"></b-col>
                            <b-col sm="5" class="text-right">
                                {{ $i18n.locale=='en' ? trainees.personal.name : trainees.personal.name_bn }}<br />
                                {{ getDesignationData(trainees.personal.professional_info, 'admin') }}
                            </b-col>
                        </b-row> -->
                    </b-overlay>
                </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-container>
  </template>
  <script>
  import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
  import { billPaymentHonorarumDetailsIndividual, honoraruimPdf, wordExportHonorarium } from '../../api/routes'
//   import ExportPdf from './export_pdf_details_individual'
  import ListReportHead from '@/components/custom/ExternalListReportHead.vue'
  import moment from 'moment'
  export default {
    components: {
        ListReportHead
    },
    // name: 'FormLayout',
    props: ['item'],
    created () {
        this.getCircularMemoNo()
        this.venueMnagement = this.item
        this.name = this.$i18n.locale === 'en' ? this.item.name : this.item.name_bn
    },
    data () {
      return {
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
        trainees: {},
        formattedDate1: '',
        formattedDate2: '',
        designation_data: '',
        org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
        workplace_data: '',
        load: false,
        venueMnagement: [],
        slOffset: 1
      }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
        getWorkPlace (item, type) {
            if (parseInt(item.p_profession_type) === 1 && item.not_here_office === 0) {
                // return ',' + this.getOfficeName(item.office_id) + ' ' + this.getOfficeAddress(item.office_id)
                this.workplace_data = this.getOfficeName(item.office_id, type) + ', ' + (this.$i18n.locale === 'bn' ? item.office_mail_address_bn : item.office_mail_address)
                return this.getOfficeName(item.office_id, type) + ', ' + (this.$i18n.locale === 'bn' ? item.office_mail_address_bn : item.office_mail_address)
            } else if (parseInt(item.p_profession_type) === 1 && item.not_here_office === 1) {
                this.workplace_data = ((this.$i18n.locale === 'bn' ? item.p_office_name_bn : item.p_office_name))
                return ((this.$i18n.locale === 'bn' ? item.p_office_name_bn : item.p_office_name))
            } else {
                this.workplace_data = (this.$i18n.locale === 'bn' ? item.p_office_name_bn : item.p_office_name)
                return ((this.$i18n.locale === 'bn' ? item.p_office_name_bn : item.p_office_name))
            }
        },
        getOfficeName (id, type) {
            let office
            if (type === 'admin') {
                office = this.$store.state.commonObj.officeList.find(item => item.value === id)
            }
            if (type === 'panel') {
                office = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(item => item.value === id)
            }
            if (this.$i18n.locale === 'bn') {
                return office !== undefined ? office.text_bn : ''
            } else {
                return office !== undefined ? office.text_en : ''
            }
        },
        getDesignationData (item, type) {
            if (parseInt(item.not_here_designation) === 0 && parseInt(item.p_profession_type) === 1) {
                let desigObj
                if (type === 'admin') {
                desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === item.designation_id)
                }
                if (type === 'panel') {
                desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === item.designation_id)
                }
                if (typeof desigObj !== 'undefined') {
                    this.designation_data = this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
                    return this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
                } else {
                    this.designation_data = ''
                    return ''
                }
            } else {
                this.designation_data = this.$i18n.locale === 'en' ? item.p_designation_en : item.p_designation_bn
                return this.$i18n.locale === 'en' ? item.p_designation_en : item.p_designation_bn
            }
        },
        convertToBengali (number) {
            const bengaliNumbers = ['শূন্য', 'এক', 'দুই', 'তিন', 'চার', 'পাঁচ', 'ছয়', 'সাত', 'আট', 'নয়', 'দশ']
            return bengaliNumbers[number]
        },
        convertToEnglish (number) {
            const englishNumbers = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']
            return englishNumbers[number]
        },
        convertToBanglaNumbers (input) {
            function toBanglaNumber (match) {
                const numberMap = {
                0: '০',
                1: '১',
                2: '২',
                3: '৩',
                4: '৪',
                5: '৫',
                6: '৬',
                7: '৭',
                8: '৮',
                9: '৯'
                }
                return match.split('').map(char => numberMap[char] || char).join('')
            }
            return input.replace(/[0-9]/g, toBanglaNumber)
        },
        getDesignation (data) {
            if (data.designation_id && data.not_here_designation === 0) {
                return this.$i18n.locale === 'en' ? this.trainees.designation_name : this.trainees.designation_name_bn
            } else if (data.not_here_designation === 1) {
                return this.$i18n.locale === 'en' ? data.designation_en : data.designation_bn
            } else {
                return ' '
            }
        },
        async getCircularMemoNo () {
            this.load = true
            const circularMemoNo = {
                circular_memo_no: this.item.circular_memo_no,
                batch_no: this.item.batch_no,
                mobile: this.item.mobile,
                payment_date: this.item.payment_date
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, billPaymentHonorarumDetailsIndividual, circularMemoNo)
            if (!result.success) {
                this.trainees = {}
                this.load = false
            } else {
                this.trainees = result.data
                this.formattedDate1 = moment(this.trainees.payment_date).locale('bn').format('DD MMMM YYYY')
                this.formattedDate2 = moment(this.trainees.payment_date).format('DD MMMM YYYY')
                const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === parseInt(result.data.training_title_id))
                if (typeof trainingTitleObj !== 'undefined') {
                    this.trainees.title_name_en = trainingTitleObj.text_en
                    this.trainees.title_name_bn = trainingTitleObj.text_bn
                } else {
                    this.trainees.title_name_en = ''
                    this.trainees.title_name_bn = ''
                }
                const desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === parseInt(result.data.personal.professional_info.designation_id))
                if (typeof desigObj !== 'undefined') {
                    this.trainees.designation_name = desigObj.text_en
                    this.trainees.designation_name_bn = desigObj.text_bn
                } else {
                    this.trainees.designation_name = ''
                    this.trainees.designation_name_bn = ''
                }
                const OfficeTypeListObj = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item1 => item1.value === parseInt(result.data.office_type_id))
                if (typeof OfficeTypeListObj !== 'undefined') {
                    this.trainees.office_type = OfficeTypeListObj.text_en
                    this.trainees.office_type_bn = OfficeTypeListObj.text_bn
                } else {
                    this.trainees.office_type = ''
                    this.trainees.office_type_bn = ''
                }
                const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(doc => doc.value === parseInt(result.data.office_id))
                if (typeof officeObj !== 'undefined') {
                    this.trainees.office = officeObj.text_en
                    this.trainees.office_bn = officeObj.text_bn
                } else {
                    this.trainees.office = ''
                    this.trainees.office_bn = ''
                }
                this.load = false
            }
        },
        print () {
            var prtContent = document.getElementById('print')
            var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
            WinPrint.document.write(prtContent.innerHTML)
            WinPrint.document.close()
            WinPrint.focus()
            WinPrint.print()
            WinPrint.close()
        },
    async wordDownloadExport () {
      const sendData = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
        circular_memo_no: this.item.circular_memo_no,
        batch_no: this.item.batch_no,
        mobile: this.item.mobile,
        payment_date: this.item.payment_date,
        designation_data: this.designation_data,
        workplace_data: this.workplace_data
      }
      const params = Object.assign(
        {
          request_type: 'word',
          local: this.$i18n.locale,
          org_id: this.orgId,
          id: this.$route.query.id
        },
        sendData
      )

      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      try {
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, wordExportHonorarium, params)

        // Create a Blob and object URL
        var blob = new Blob([result], {
          type: 'application/msword' // Use 'application/msword' for Word documents
        })

        var url = window.URL.createObjectURL(blob)

        // Create a temporary link element to trigger the download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'speaker_honorarium.docx' // Set the desired filename with .docx extension for Word documents

        // Trigger the download and cleanup
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        // Handle any errors here
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
        pdfExport () {
            // Get HTML to print from element
            const prtHtml = document.getElementById('print').innerHTML
            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" media="print">
            <style>
                @media print {
                    .row::after {
                        clear: both;
                    }
                    .row::before {
                        display: table;
                        content: " ";
                    }
                    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6,
                .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md-4, col-md-8 {
                    float: left;
                }

                .col-lg-12 {
                    width: 100%;
                }

                .col-lg-11 {
                    width: 91.66666666666666%;
                }

                .col-lg-10 {
                    width: 83.33333333333334%;
                }

                .col-lg-9 {
                        width: 75%;
                }

                .col-lg-8 {
                        width: 66.66666666666666%;
                }

                .col-lg-7 {
                        width: 58.333333333333336%;
                }

                .col-lg-6 {
                        width: 50%;
                }

                .col-lg-5 {
                        width: 41.66666666666667%;
                }

                .col-lg-4 {
                        width: 33.33333333333333%;
                }

                .col-lg-3 {
                        width: 25%;
                }

                .col-lg-2 {
                        width: 16.666666666666664%;
                }

                .col-lg-1 {
                        width: 8.333333333333332%;
                    }
                    .pt-4 { padding-top: 20px }
                    .border {
                    border: 1px solid #dee2e6 !important;
                    border-width: 1px !important;
                        border-style: solid !important;
                        border-color: #DB4237 !important;
                        font-size: 10px !important;
                        background-color: !important;
                        /*padding:0px;*/
                    -webkit-print-color-adjust:exact !important;
                    }
                    .border-bottom {
                        border-bottom: 1px solid #dee2e6 !important;
                        border-bottom-color: rgb(222, 226, 230);
                    }
                    .border-dark {
                        border-color: #343a40 !important;
                    }
                    .margin-top-0 {
                    margin-top: 0 !important;
                    }
                    .text-center { text-align: center; }
                    .margin-top-20 { margin-top: 40px; }
                    .my-3 {
                    margin:20px 0 20px;
                    }
                }
            </style>
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`)

            WinPrint.document.close()
            WinPrint.focus()
            WinPrint.print()
            WinPrint.close()
        },
        async pdfExportHonaraium () {
            this.load = true
            const obj = {
                role: this.$store.state.Auth.activeRoleId,
                circular_memo_no: this.item.circular_memo_no,
                batch_no: this.item.batch_no,
                mobile: this.item.mobile,
                designation_data: this.designation_data,
                workplace_data: this.workplace_data,
                payment_date: this.item.payment_date,
                formattedDate1: this.formattedDate1,
                formattedDate2: this.formattedDate2,
                name: this.name
            }
            const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, obj)
            const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, honoraruimPdf, params)
            var blob = new Blob([result], {
                type: 'application/pdf'
            })
            this.load = false
            var url = window.URL.createObjectURL(blob)
            window.open(url).print()
        }
    }
  }
  </script>
  <style>
    .hidden_header {
      display: none
    }
    .card-border {
      border: 1px solid #b9bdc1;
      box-shadow: 1px 1px 6px -1px grey;
      background-color: #dee2e6;
    }
    [dir=ltr][mode=light] .card-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding: 10px;
    }
    .report-name{
      font-weight: bold !important;
      text-transform: uppercase;
    }
    .my-btn{
      padding: 2px !important;
    }
  </style>
