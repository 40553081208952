<template>
    <b-container fluid>
        <b-row>
          <b-col lg="12" sm="12">
            <iq-card>
                <!-- <template v-slot:headerAction>
                    <b-button variant="primary" @click="pdfExport" class="">
                        <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
                    </b-button>
                </template> -->
                <template>
                    <b-overlay :show="load">
                        <b-row>
                            <b-col>
                                <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="org_id">
                                    <div class="titleDiv">
                                        {{ $t('elearning_tim.individual_honorarium_title') }}
                                    </div>
                                </list-report-head>
                            </b-col>
                        </b-row>
                        <b-row v-if="trainees.length !== 0">
                            <b-col lg="12" sm="12" class="pl-5 pr-5">
                                <b-row>
                                    <b-col lg="12" sm="12" class="text-left mt-1 mb-3">
                                        <p>{{ $t('elearning_tim.individual_honorarium_1') + ' ‘‘' + (($i18n.locale=='en') ? trainees.title_name_en : trainees.title_name_bn) + '’’ ' + $t('elearning_tim.individual_honorarium_2') + ' ' + (($i18n.locale=='en') ? item.name : item.name_bn) + ', ' + (($i18n.locale=='en') ? item.designation : item.designation_bn) + ', ' + (($i18n.locale=='en') ? item.office : item.office_bn) + ' ' + $t('elearning_tim.individual_honorarium_3') + ' ' +  $n(trainees.honour_amount * trainees.total_days) + ' ' + $t('elearning_tim.individual_honorarium_4') + ' ' +  $n(trainees.vat_tax, { minimumFractionDigits: 2 }) + '% ' + $t('elearning_tim.individual_honorarium_5') + ' ' +  $n(trainees.vat_tax_amount, { minimumFractionDigits: 2 }) + ' ' + $t('elearning_tim.individual_honorarium_6') }}</p>
                                    </b-col>
                                </b-row>
                                <b-table-simple bordered>
                                    <b-tr>
                                        <b-th style="width:15%">{{ $t('globalTrans.date') }}</b-th>
                                        <b-th style="width:10%">{{ $t('elearning_tim.session_hrs') }}</b-th>
                                        <b-th style="width:15%">{{ $t('elearning_tim.honorarium_per_per_hrs') }}</b-th>
                                        <b-th style="width:15%">{{ $t('elearning_tim.total_honorarium') }}</b-th>
                                        <b-th style="width:15%">{{ $n(trainees.vat_tax, { minimumFractionDigits: 2 }) + '% ' + $t('elearning_tim.of_total_honorarium') }}</b-th>
                                        <b-th style="width:15%">{{ $t('elearning_tim.net_honorarium') }}</b-th>
                                        <b-th style="width:15%">{{ $t('globalTrans.remarks') }}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <!-- <b-td>{{ ($i18n.locale=='en') ? item.name_en : item.name_bn }}</b-td> -->
                                        <b-td>{{ trainees.payment_date|dateFormat }}</b-td>
                                        <b-td>{{ $n(trainees.total_days) }}</b-td>
                                        <b-td>{{ $n(trainees.honour_amount) }}</b-td>
                                        <b-td>{{ $n(trainees.honour_amount * trainees.total_days) }}</b-td>
                                        <b-td>{{ $n(trainees.vat_tax_amount, { minimumFractionDigits: 2 }) }}</b-td>
                                        <b-td>{{ $n(trainees.payment_amount, { minimumFractionDigits: 2 }) }}</b-td>
                                        <b-td></b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                        <b-row class="mt-5">
                            <b-col sm="7"></b-col>
                            <b-col sm="5" class="text-center">
                                <span v-if="$i18n.locale=='en'" v-html="trainees.circular_publication.text_coordinator_en"></span>
                                <span v-else v-html="trainees.circular_publication.text_coordinator_bn"></span>
                            </b-col>
                            <!-- <b-col sm="5" class="text-center"> -->
                                <!-- {{ $i18n.locale=='en' ? 'Dr. Muhammad Shahadat Hossain Siddiquee' : 'মুহাম্মদ শাহাদৎ হোসাইন সিদ্দিকী' }}<br />
                                {{ $i18n.locale=='en' ? 'Course Coordinator' : 'কোর্স কোর্ডিনেটর' }}<br />
                                {{ $i18n.locale=='en' ? '&' : 'ও' }}<br />
                                {{ $i18n.locale=='en' ? 'Senior Assistant Director' : 'সিনিয়ার সহকারী পরিচালক' }}<br />
                                {{ $i18n.locale=='en' ? 'NATA, Gazipur' : 'নাটা, গাজীপুর' }}<br />
                                {{ $i18n.locale=='en' ? 'Cell: 01753896598' : 'সেল: ০১৭৫৩৮৯৬৫৯৮' }}
                            </b-col> -->
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
            <!-- <pre>{{ item }}</pre> -->
          </b-col>
        </b-row>
      </b-container>
  </template>
  <script>
  import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
  import { billPaymentHonorarumIndividualDetails, incometaxCertificatePdf } from '../../api/routes'
//   import ExportPdf from './export_pdf_individual_details'
  import ListReportHead from '@/components/custom/ExternalListReportHead.vue'
  export default {
    components: {
        ListReportHead
    },
    // name: 'FormLayout',
    props: ['item'],
    created () {
        this.getCircularMemoNo()
        this.venueMnagement = this.item
    },
    data () {
      return {
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
        trainees: {},
        load: false,
        org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
        venueMnagement: [],
        slOffset: 1,
        desiData: ''
      }
    },
    computed: {
    },
    methods: {
        getDesignation (data) {
            if (data.designation_id && data.not_here_designation === 0) {
                this.desiData = this.$i18n.locale === 'en' ? this.trainees.designation_name : this.trainees.designation_name_bn
                return this.$i18n.locale === 'en' ? this.trainees.designation_name : this.trainees.designation_name_bn
            } else if (data.not_here_designation === 1) {
                this.desiData = this.$i18n.locale === 'en' ? data.designation_en : data.designation_bn
                return this.$i18n.locale === 'en' ? data.designation_en : data.designation_bn
            } else {
                this.desiData = ''
                return ' '
            }
        },
        async getCircularMemoNo () {
            this.load = true
            const circularMemoNo = {
                circular_memo_no: this.item.circular_memo_no,
                batch_no: this.item.batch_no,
                mobile: this.item.mobile,
                payment_date: this.item.payment_date
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, billPaymentHonorarumIndividualDetails, circularMemoNo)
            if (!result.success) {
                this.trainees = {}
                this.load = false
            } else {
                this.trainees = result.data
                const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === parseInt(result.data.training_title_id))
                if (typeof trainingTitleObj !== 'undefined') {
                    this.trainees.title_name_en = trainingTitleObj.text_en
                    this.trainees.title_name_bn = trainingTitleObj.text_bn
                } else {
                    this.trainees.title_name_en = ''
                    this.trainees.title_name_bn = ''
                }
                const desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === parseInt(result.data.personal.professional_info.designation_id))
                if (typeof desigObj !== 'undefined') {
                    this.trainees.designation_name = desigObj.text_en
                    this.trainees.designation_name_bn = desigObj.text_bn
                } else {
                    this.trainees.designation_name = ''
                    this.trainees.designation_name_bn = ''
                }
                const OfficeTypeListObj = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item1 => item1.value === parseInt(result.data.office_type_id))
                if (typeof OfficeTypeListObj !== 'undefined') {
                    this.trainees.office_type = OfficeTypeListObj.text_en
                    this.trainees.office_type_bn = OfficeTypeListObj.text_bn
                } else {
                    this.trainees.office_type = ''
                    this.trainees.office_type_bn = ''
                }
                const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(doc => doc.value === parseInt(result.data.office_id))
                if (typeof officeObj !== 'undefined') {
                    this.trainees.office = officeObj.text_en
                    this.trainees.office_bn = officeObj.text_bn
                } else {
                    this.trainees.office = ''
                    this.trainees.office_bn = ''
                }
                this.load = false
            }
        },
        // pdfExport () {
        //     const reportTitle = this.$t('elearning_tim.individual_honorarium_title')
        //     ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, this.trainees)
        // },
        async pdfExport () {
            this.load = true
            const obj = {
                role: this.$store.state.Auth.activeRoleId,
                auth_org: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
                circular_memo_no: this.item.circular_memo_no,
                batch_no: this.item.batch_no,
                mobile: this.item.mobile,
                payment_date: this.item.payment_date,
                desiData: this.desiData,
                name: this.item.name,
                name_bn: this.item.name_bn,
                designation: this.item.designation,
                designation_bn: this.item.designation_bn,
                office_name: this.item.office,
                office_name_bn: this.item.office_bn
            }
            const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, obj)
            const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, incometaxCertificatePdf, params)
            var blob = new Blob([result], {
                type: 'application/pdf'
            })
            this.load = false
            var url = window.URL.createObjectURL(blob)
            window.open(url).print()
        }
    }
  }
  </script>
  <style>
    .hidden_header {
      display: none
    }
    .card-border {
      border: 1px solid #b9bdc1;
      box-shadow: 1px 1px 6px -1px grey;
      background-color: #dee2e6;
    }
    [dir=ltr][mode=light] .card-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding: 10px;
    }
    .report-name{
      font-weight: bold !important;
      text-transform: uppercase;
    }
    .my-btn{
      padding: 2px !important;
    }
  </style>
